<template>
    <modal
        id="avatar"
        ref="avatar"
        :title="$t('modal.avatar.heading')"
        type="user"
    >
        <form
            id="profile"
            @submit.prevent="save"
        >
            <div class="avatar">
                <div
                    class="avatar-image"
                    :class="{ changed: changed }"
                >
                    <img
                        v-if="fileSrc != ''"
                        :src="fileSrc"
                        alt="avatar"
                    >
                    <user-icon v-else />
                </div>
                <span
                    v-if="error != ''"
                    class="error-message"
                >
                    {{ error }}
                </span>
            </div>

            <input
                ref="file"
                type="file"
                name="avatar"
                accept=".jpg,.jpeg,.webp"
                @change="filesChange($event.target.files)"
            >
            <div class="file-actions d-flex">
                <b-button
                    v-if="fileSrc != ''"
                    variant="text"
                    class="icon link"
                    @click="remove()"
                >
                    <trash-2-icon />
                </b-button>
                <b-button
                    variant="text"
                    class="icon link"
                    @click="$refs.file.click()"
                >
                    <upload-icon />
                </b-button>
            </div>
            <div class="actions">
                <b-button
                    variant="primary"
                    class="link lg cancel"
                    @click="closeModal()"
                >
                    {{ $t('modal.general.cancelButton') }}
                </b-button>
                <b-button
                    :disabled="sending"
                    variant="primary"
                    class="fill lg save"
                    type="submit"
                >
                    {{ $t('modal.general.uploadButton') }}
                </b-button>
            </div>
        </form>
    </modal>
</template>

<script>
    import Trash2Icon from 'vue-feather-icons/icons/Trash2Icon';
    import UploadIcon from 'vue-feather-icons/icons/UploadIcon';
    import UserIcon from 'vue-feather-icons/icons/UserIcon';
    import { mapActions, mapState } from 'vuex';

    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal';
    export default {
        name: 'AvatarModal',
        components: {
            Modal,
            UserIcon,
            Trash2Icon,
            UploadIcon,
        },
        data() {
            return {
                changed: false,
                fileSrc: '',
                file: null,
                error: '',
                sending: false,
            };
        },
        computed: {
            ...mapState('user', ['generalData', 'inputErrors', 'avatar']),
        },
        methods: {
            ...mapActions('user', ['removeAvatar', 'replaceAvatar']),
            closeModal() {
                this.$refs.avatar.closeModal();
                this.initialize();
            },
            onHide(evt) {
                if (evt.trigger === 'backdrop') {
                    this.initialize();
                }
            },
            async save() {
                this.errors = {};
                if (!this.file) {
                    this.error = this.$t('form.validation.file_missing');
                    return;
                }
                if (this.file.size > 1024 * 1024) {
                    this.error = this.$t('form.validation.file_max_size', {
                        size: '1MB',
                    });
                    return;
                }
                if (this.file['type'] != 'image/jpeg') {
                    this.error = this.$t('form.validation.file_mime', {
                        mime: 'jpeg, jpg, png',
                    });
                    return;
                }
                let formData = new FormData();
                formData.append('avatar', this.file);
                this.sending = true;
                await this.replaceAvatar(formData);
                this.sending = false;
                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }
                this.closeModal();
            },
            filesChange(files) {
                this.changed = true;
                this.file = files[0];
                this.fileSrc = URL.createObjectURL(files[0]);
            },
            remove() {
                this.removeAvatar();
                this.closeModal();
            },
            initialize() {
                this.changed = false;
                this.file = null;
                this.fileSrc = this.avatar;
                this.error = '';
            },
        },
        created() {
            this.fileSrc = this.avatar;
        },
        watch: {
            avatar: {
                handler() {
                    this.fileSrc = this.avatar;
                },
                deep: true,
            },
        },
    };
</script>
<style scoped lang="scss">
.avatar {
  height: 104px;
  width: 100%;
  text-align: center;
  .avatar-image {
    height: 84px;
    width: 84px;
    background-color: $gray-thin;
    border-radius: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    svg {
      height: 42px;
      width: 42px;
    }
    .remove {
      position: absolute;
      cursor: pointer;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: $white;
      border-radius: 50%;
      box-shadow: none;
      box-shadow: 1px 5px 5px 1px rgb(0 0 0 / 20%);
      text-align: center;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    img {
      border-radius: 42px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &.changed {
      img {
        opacity: 0.5;
      }
    }
  }
  .error-message {
    display: block;
    font-size: 12px;
    margin-top: 4px;
    color: $red;
  }
}
input {
  display: none;
}
.file-actions {
  justify-content: center;
  width: 100%;
  .btn {
    margin: 0;
  }
}
</style>
