<template>
    <div>
        <div
            :id="id"
            class="modal fade"
            :class="{ show: showModal }"
            tabindex="-1"
            role="dialog"
        >
            <div
                class="modal-dialog"
                role="document"
            >
                <div class="modal-header">
                    <h6 class="lg-sbold heading">
                        {{ title }}
                    </h6>
                    <b-button
                        variant="link"
                        class="close icon"
                        type="button"
                        aria-label="Close"
                        @click="closeModal"
                    >
                        <x-icon />
                    </b-button>
                </div>
                <div class="modal-body">
                    <slot />
                </div>
            </div>
            <div
                class="modal-backdrop fade"
                :class="{ show: showModal }"
                @click="closeModalOnBackdrop"
            />
        </div>
    </div>
</template>

<script>
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapMutations } from 'vuex';
    export default {
        name: 'Modal',
        components: {
            XIcon,
        },
        data() {
            return {
                showModal: false,
            };
        },
        props: {
            type: {
                type: String,
                default: '',
            },
            title: {
                type: String,
                default: '',
            },
            id: {
                type: String,
                default: '',
            },
            backdropClose: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            ...mapMutations('product', ['setModal']),
            ...mapMutations('user', { setUserModal: 'setModal' }),
            ...mapMutations('general', { setGeneralModal: 'setModal' }),
            closeModal() {
                this.showModal = false;
                setTimeout(() => {
                    this.$emit('close');
                    switch (this.type) {
                    case 'product':
                        this.setModal({
                            show: false,
                            type: this.id,
                        });
                        break;
                    case 'general':
                        this.setGeneralModal({
                            show: false,
                            type: this.id,
                        });
                        break;
                    case 'user':
                        this.setUserModal({
                            show: false,
                            type: this.id,
                        });
                        break;
                    default:
                        break;
                    }
                }, 100);
            },
            closeModalOnBackdrop() {
                if (this.backdropClose) {
                    this.closeModal();
                }
            },
        },
        mounted() {
            setTimeout(() => {
                this.showModal = true;
                document.body.classList.add('modal-open');
            }, 100);
        },
        beforeDestroy() {
            document.body.classList.remove('modal-open');
        },
    };
</script>

<style lang="scss" scoped>
.modal {
  display: block;
  opacity: 0;
  &.show {
    opacity: 1;
  }
  .modal-dialog {
    z-index: 1001;
    pointer-events: initial;
    display: block;
    position: relative;
    min-width: 300px;
    max-width: 450px;
    background-color: $white;
    padding: 24px;
    border-radius: 8px;
    @include media-breakpoint-down(xm) {
      padding: 20px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 6rem;
    }
    @include media-breakpoint-down(sm) {
      margin: 0.5rem auto;
      width: calc(100% - 1rem);
    }
    .modal-header {
      padding: 0;
      border: none;
      margin-bottom: 40px;
      @include media-breakpoint-down(xm) {
        margin-bottom: 24px;
      }
      h6 {
        color: $text;
        padding-right: 32px;
        text-transform: none;
        letter-spacing: normal;
      }
      .modal-heading-text {
        line-height: 18px;
        padding-right: 32px;
      }
      .close {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;
        height: 24px;
        width: 24px;
        margin: 0;
        padding: 0;
        @include media-breakpoint-down(xm) {
          top: 8px;
          right: 8px;
        }
      }
    }
    .modal-body {
      padding: 0;
      .separator {
        gap: 24px;
        margin: 24px 0px;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(xm) {
          margin: 12px 0px;
        }
        hr {
          flex: 1;
          color: $gray-light;
        }
        p {
          color: $gray;
          text-transform: uppercase;
        }
      }
      .member {
        margin-top: 24px;
        justify-content: center;
        p {
          color: $text;
          &.login,
          &.re-send {
            color: $primary;
            cursor: pointer;
          }
        }
        span {
          cursor: pointer;
          font-size: 14px;
          line-height: 24px;
          font-weight: 600;
          color: $primary;
        }
      }
      strong {
        color: $primary;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 12px;
      }
      caption {
        color: $text;
        margin-top: 4px;
      }
    }
  }
  .modal-backdrop {
    opacity: 0.5;
    z-index: 1000;
  }
}

#logout {
  .modal-header {
    margin: 0;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
</style>
